@import url("https://fonts.googleapis.com/css?family=Open+Sans");

* {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  padding: 24px;
  font-size: 14px;
  line-height: 24px;
  overflow-y: scroll;
  display: flex;
  font-family: "Open Sans", sans-serif;
  background: #f0f2f5;
  flex-direction: column;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*min-width: 1100px;*/
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  font-style: normal;
  font-weight: bold;
  background: #ffffd4;
}

.search-container {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  width: 95%;
  min-height: 100%;
  margin: 0 auto;
}

@media (max-width: 1007px) {
  .search-container {
    width: 100%;
  }
}

#primary-description-facet,
#secondary-description-facet {
  margin-bottom: 5%;
}

#primary-description-facet,
#secondary-description-facet {
  margin-right: 5%;
  padding: 5% 0;
}

#primary-description-facet,
#secondary-description-facet,
.ais-Hits-item,
.ais-SearchBox-input {
  height: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.ais-Hits-item {
  width: 100%;
}
.ais-SearchBox-input:focus {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.left-panel {
  flex: 0 0 20%;
}

.right-panel {
  width: 75%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ais-Hits-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

#searchbox {
  flex-grow: 0;
  margin-bottom: 1%;
}

.ais-SearchBox {
  position: relative;
  height: auto;
  width: 100%;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  position: absolute;
  top: 0;
  left: inherit;
  margin: 14px 16px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.ais-SearchBox-submit svg,
.ais-SearchBox-reset svg {
  width: 13px;
  height: 13px;
  display: block;
}

.ais-SearchBox-submit svg {
  fill: #5468ff;
}

.ais-SearchBox-reset svg {
  fill: #ed5a6a;
}

.ais-SearchBox-submit {
  right: 0;
}

.ais-SearchBox-reset {
  background: none;
  padding: 0;
  border: none;
  right: 30px;
}

.ais-SearchBox-input {
  width: 100%;
  padding: 8px 16px;
  font-weight: normal;
  border: none;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  -webkit-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.ais-Panel-header {
  font-weight: bolder;
  margin: 0;
  padding: 0 16px 8px;
  font-size: inherit;
  text-transform: uppercase;
  border-bottom: 1px solid #eee;
}

.ais-Hits-item {
  margin-bottom: 2%;
}

.hit {
  display: flex;
  padding: 2%;
  overflow: auto;
  justify-items: left;
}

.ais-RefinementList-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ais-RefinementList-item,
.ais-RefinementList-noResults {
  padding: 8px 16px;
}

.ais-RefinementList-label {
  cursor: pointer;
}

.ais-RefinementList-labelText,
.ais-RefinementList-labelText:hover {
  color: black;
  cursor: pointer;
}

.ais-RefinementList-count {
  background: none;
  padding: 0;
  font-size: 12px;
  line-height: 24px;
  color: #999;
  float: right;
}

.ais-RefinementList-item--selected .ais-RefinementList-label {
  font-weight: 700;
}

.ais-RefinementList-checkbox {
  margin: 0;
}

.ais-RefinementList-labelText {
  margin-left: 0.6em;
}

.ais-RefinementList-item--selected .ais-RefinementList-labelText,
.ais-RefinementList-item--selected .ais-RefinementList-count {
  font-weight: normal;
}

.ais-Pagination-list {
  display: flex;
  margin: 0 0 16px;
  padding: 0;
  background: none;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  box-shadow: none;
  flex-wrap: wrap;
}

.ais-Pagination-link {
  display: block;
}

.ais-Pagination-item,
.ais-Pagination-item--disabled {
  flex: 0 0 40px;
  width: auto;
  height: auto;
  margin: 4px;
  background-color: #5468ff;
  list-style: none outside none;
  text-align: center;
  border-radius: 4px;
  transition: all 0.1s linear;
}

.ais-Pagination-item--disabled {
  display: none;
}

.ais-Pagination-item:hover,
.ais-Pagination-item--selected {
  background-color: #3a46a1;
}

.ais-Pagination-item .ais-Pagination-link,
.ais-Pagination-item--disabled .ais-Pagination-link {
  padding: 6px 16px;
  text-decoration: none;
  color: #fff;
  line-height: 20px;
}

.ais-Stats {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 12px;
  color: #697782;
  opacity: 1;
  padding-bottom: 1%;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  flex-grow: 0;
  text-align: center;
  font-size: 12px;
  color: #999;
}

dl {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

dt {
  width: 25%;
}

dd {
  margin-left: auto;
  width: 75%;
  margin-bottom: 10px;
}
